
#form {
    width: 280px;
    margin: 0 auto;
}

#form * {
    box-sizing: border-box;
}

#form p{
    text-align: center;
    margin-bottom: 30px;
}

#form input {
    margin-bottom: 15px;
}

#form input[type=text] {
    display: block;
    height: 32px;
    padding: 6px 16px;
    width: 100%;
    border: none;
    background-color: #f3f3f3;
}

#form label {
    color: #777;
    font-size: 0.8em;
}

#form input[type=submit] {
    display: block;
    margin: 20px auto 0;
    width: 150px;
    height: 40px;
    border-radius: 25px;
    border: none;
    color: #eee;
    font-weight: 700;

    background: #207cca; /* Old browsers */
    background: -moz-linear-gradient(left, #207cca 0%, #9f58a3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #207cca 0%,#9f58a3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #207cca 0%,#9f58a3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#207cca', endColorstr='#9f58a3',GradientType=1 ); /* IE6-9 */
}